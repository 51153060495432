import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {isEmail} from "../../utils/uitls";
import {ERR_BAD_REQUEST, postData} from "../network/network";
import {User, userUtility} from "../../models/user";
import {AxiosError} from "axios";
import {Image} from "react-bootstrap";
import {MDBInput} from "mdb-react-ui-kit";

export const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const navigate = useNavigate()

    const onButtonClick = () => {
        if (email.length === 0 || !isEmail(email)) {
            setPasswordError('email incorrect')
            return
        }
        if (password.length === 0) {
            setPasswordError('password incorrect')
            return
        }
        postData({
            url: "/api/users/login",
            data: {
                "email": email,
                "password": password
            },
            onError: (e: AxiosError) => {
                switch (e.code) {
                    case ERR_BAD_REQUEST:
                        setPasswordError("password or email is incorrect")
                        break
                    default:
                        setPasswordError("service unavailable")
                }
                setIsLoading(false)
            },
            onSuccess: (data: User) => {
                userUtility.setUserData(data)
                navigate('/')
                setIsLoading(false)
            }
        })
        setIsLoading(true)
    }
    return (
        <div className="container-fluid">
            <div className="vh-100 row justify-content-center align-items-center">
                <div className="col-md-9 col-lg-6 col-xl-5">
                    <Image src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" fluid/>
                </div>
                <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <form>
                        <div className="form-outline mb-4">
                            <MDBInput
                                type="email"
                                className="form-control form-control-lg"
                                id="email"
                                value={email}
                                label="Enter your email here"
                                onChange={(ev: any) => setEmail(ev.target.value)}/>
                        </div>

                        <div className="form-outline mb-3">
                            <MDBInput
                                type="password"
                                className="form-control form-control-lg"
                                id="password"
                                value={password}
                                label="Enter your password here"
                                onChange={(ev: any) => setPassword(ev.target.value)}/>
                        </div>

                        <div className="text-center text-lg-start mt-4 pt-10">
                            {isLoading ?
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                : <button type="button" className="btn btn-primary btn-lg"
                                          onClick={onButtonClick}>Login</button>}
                            {passwordError.length > 0 && <label className="errorLabel ms-2">{passwordError}</label>}
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
