import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Task} from "../../models/task";
import {ERR_BAD_REQUEST, getData} from "../network/network";
import {AxiosError} from "axios";
import {getPendingWith} from "../../utils/uitls";
import {MDBBadge, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";

export const TaskList = () => {
    const [data, setData] = useState<Task[]>([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setLoading(true)
        getData({
            url: '/api/task/fetch-all',
            data: {
              'status': params.get('status') ?? '',
            },
            onSuccess: (list: Task[]) => {
                setData(list)
                setLoading(false)
            },
            onError: (err: AxiosError) => {
                switch (err.code) {
                    case ERR_BAD_REQUEST:
                        setError(`${err?.response?.data}`)
                        break
                    default:
                        setError("service unavailable")
                }
                setLoading(false)
            }
        })
    }, [location.search]);

    if (loading) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }

    if (error.length > 0) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    {error}
                </div>
            </div>
        )
    }

    if (data.length === 0) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    No data available
                </div>
            </div>
        )
    }

    return (
        <div className="container mt-4">
            <MDBTable align='middle' striped hover>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Product Name</th>
                        <th scope='col'>Project type</th>
                        <th scope='col'>Status</th>
                        <th scope='col'>Country</th>
                        <th scope='col'>Brand Name</th>
                        <th scope='col'>Priority</th>
                        <th scope='col'>Pending With</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {data.map((item: Task) => {
                        return (
                            <tr onClick={() => {
                                navigate(`/home/task-detail/${item.id}`)
                            }}>
                                <td>
                                    <div className='d-flex align-items-center'>
                                        <div className='ms-3'>
                                            <p className='fw-bold mb-1'>{item.project_name}</p>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <p className='fw-normal mb-1'>{item.project_type}</p>

                                </td>
                                <td>
                                    {item.status >= 0 && item.status < item.actions.length ?
                                        <MDBBadge color='warning' pill>
                                            In-Process
                                        </MDBBadge> : <MDBBadge color='success' pill>
                                            Completed
                                        </MDBBadge>}
                                </td>
                                <td><p className='text-muted mb-0'>{item.country_name}</p></td>
                                <td>{item.brand_name}</td>
                                <td>{item.priority === '0'?
                                    <MDBBadge color='info' pill>
                                        Normal
                                    </MDBBadge> : <MDBBadge color='danger' pill>
                                        High
                                    </MDBBadge>}</td>
                                <td><p className='text-muted mb-0'>{getPendingWith(item)}</p></td>
                            </tr>
                        )
                    })}
                </MDBTableBody>
            </MDBTable>
        </div>
    )
}
