import {Task} from "../models/task"

export const isEmail = (search: string) => {
    const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regexp.test(search)
}

export const isPhone = (phone: string) => {
    const regex = /^\d+$/
    return phone.length === 10 && regex.test(phone);
}


export const getPendingWith = (task: Task): string => {
    return task.actions[task.status ?? 0]?.value
}

export const getApproverEmail = (task: Task): string => {
    return task.actions[3]?.value
}

export const getFormatedDate = (date: string) => {
    const d = new Date(date);
    return [d.getMonth()+1,
            d.getDate(),
            d.getFullYear()].join('/')+' '+
        [d.getHours(),
            d.getMinutes()].join(':');
}
