import React from 'react';
import { Home } from "./component/home/Home";
import {
  BrowserRouter as Router, Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { Login } from "./component/login/Login";
import { CreateAccount } from "./component/createAccount/CreateAccount";
import { CreateTask } from "./component/createTask/CreateTask";
import { TaskList } from "./component/taskList/TaskList";
import {TaskDetail} from "./component/taskDetail/TaskDetail";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/home" element={<Home />}>
          <Route index element={<TaskList/>}/>
          <Route path="createAccount" element={<CreateAccount />}/>
          <Route path="createTask" element={<CreateTask />}/>
          <Route path="task-detail/:id" element={<TaskDetail />}/>
        </Route>
        <Route path="/Login" element={<Login />}/>
        <Route path="/" element={<Navigate to={"/home"} replace={true}/> }/>
      </Routes>
    </Router>
  );
}

export default App;
