import React, {forwardRef, useImperativeHandle, useRef, useState} from "react";
import {getApproverEmail, getPendingWith, isEmail} from "../../utils/uitls";
import {ERR_BAD_REQUEST, postData} from "../network/network";
import {AxiosError} from "axios";
import {ResponseOuter, Task} from "../../models/task";
import {userUtility} from "../../models/user";
import {useNavigate} from "react-router-dom";
import {Button, Modal} from "react-bootstrap";
import {TextEditor} from "../textEditor/TextEditor";
import {MDBCol, MDBInput, MDBRadio, MDBRow} from "mdb-react-ui-kit";

type CreateTaskProps = {
    task?: Task | null;
    updateTask?: (task: Task) => void;
}


export const CreateTask = (props: CreateTaskProps) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(props.task !== undefined)
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const [projectName, setProjectName] = useState(props.task?.project_name ?? '')
    const [projectType, setProjectType] = useState(props.task?.project_type ?? '')
    const [brandName, setBrandName] = useState(props.task?.brand_name ?? '')
    const [priority, setPriority] = useState(props.task?.priority.toString() ?? '0')
    const [packType, setPackType] = useState(props.task?.pack_type.toString() ?? '')
    const [packSize, setPackSize] = useState(props.task?.pack_size.toString() ?? '')
    const [country, setCountry] = useState(props.task?.country_name ?? '')
    const [gsm, setGsm] = useState(props.task?.gsm ?? '')
    const [initiator, setInitiator] = useState(props.task?.actions[0].value ?? '')
    const [reviewor1, setReviewor1] = useState(props.task?.actions[1].value ?? '')
    const [reviewor2, setReviewor2] = useState(props.task?.actions[2].value ?? '')
    const [approver, setApprover] = useState(props.task?.actions[3].value ?? '')
    const navigate = useNavigate()

    const modelRef = useRef<UploadFileCommentModalRef>(null)
    const onEditClick = () => {
        setIsDisabled(!isDisabled)
    }

    const onButtonClick = () => {
        if (projectName.length === 0) {
            setError('projectName should not be empty')
            return
        }
        if (projectType.length === 0) {
            setError('projectType should not be empty')
            return
        }
        if (brandName.length === 0) {
            setError('brandName should not be empty')
            return
        }
        if (priority.length === 0) {
            setError('priority should not be empty')
            return
        }
        if (packType.length === 0) {
            setError('packType should not be empty')
            return
        }
        if (packSize.length === 0) {
            setError('packSize should not be empty')
            return
        }
        if (country.length === 0) {
            setError('country should not be empty')
            return
        }
        if (initiator.length === 0 || !isEmail(initiator)) {
            setError('initiator should not be empty or incorrect email')
            return
        }
        if (reviewor1.length === 0 || !isEmail(reviewor1)) {
            setError('reviewor1 should not be empty or incorrect email')
            return
        }
        if (reviewor2.length === 0 || !isEmail(reviewor2)) {
            setError('reviewor2 should not be empty or incorrect email')
            return
        }
        if (approver.length === 0 || !isEmail(approver)) {
            setError('approver should not be empty or incorrect email')
            return
        }
        postData({
            url: "/api/task/create",
            data: {
                'id': props.task?.id ?? -1,
                "project_name": projectName,
                "project_type": projectType,
                "brand_name": brandName,
                "priority": priority,
                "pack_type": packType,
                "pack_size": packSize,
                "country_name": country,
                "gsm": gsm,
                "actions": [{
                    "position": 1,
                    "key": "initiator",
                    "value": initiator
                }, {
                    "position": 2,
                    "key": "reviewer_one",
                    "value": reviewor1
                }, {
                    "position": 3,
                    "key": "reviewer_two",
                    "value": reviewor2
                },
                    {
                        "position": 4,
                        "key": "approver",
                        "value": approver
                    }]
            },
            onSuccess: (task: ResponseOuter<Task>) => {
                if (props.task?.id) {
                    props.updateTask?.(task.data)
                } else {
                    navigate('/home')
                }
                setLoading(false)
                setError(task.message)
            },
            onError: (e: AxiosError) => {
                switch (e.code) {
                    case ERR_BAD_REQUEST:
                        setError(`${e?.response?.data}`)
                        break
                    default:
                        setError("service unavailable")
                }
                setLoading(false)
            }
        }, navigate)

        setLoading(true)
    }


    return (
        <div className="container">
            {props.task && <UploadFileCommentModal ref={modelRef} {...props} />}
            {
                !props.task && <div className="row justify-content-center align-items-center m-4">
                    Create Task
                </div>
            }

            <form>
                <div className="row">
                    <div className="col form-outline mb-3">
                        <MDBInput
                            disabled={isDisabled}
                            value={projectName}
                            onChange={(ev: any) => setProjectName(ev.target.value)}
                            type="text"
                            className="form-control form-control-lg"
                            label="Project Name"
                        />
                    </div>

                    <div className="col form-outline mb-3">
                        <MDBInput
                            disabled={isDisabled}
                            value={projectType}
                            onChange={(ev: any) => setProjectType(ev.target.value)}
                            type="text"
                            className="form-control form-control-lg"
                            label="Project Type"/>
                    </div>
                    <div className="col form-outline mb-3">
                        <MDBInput
                            disabled={isDisabled}
                            value={brandName}
                            onChange={(ev: any) => setBrandName(ev.target.value)}
                            type="text"
                            className="form-control form-control-lg"
                            label="Brand Name"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col form-outline mb-3">
                        <MDBInput
                            disabled={isDisabled}
                            value={gsm}
                            onChange={(ev: any) => setGsm(ev.target.value)}
                            type="text"
                            className="form-control form-control-lg"
                            label="Type and GSM of Board"/>
                    </div>
                    <div className="col form-outline mb-3">
                        <MDBInput
                            disabled={isDisabled}
                            value={packType}
                            onChange={(ev: any) => setPackType(ev.target.value)}
                            type="text"
                            className="form-control form-control-lg"
                            label="Pack Type"/>
                    </div>
                    <div className="col form-outline mb-3">
                        <MDBInput
                            disabled={isDisabled}
                            value={packSize}
                            onChange={(ev: any) => setPackSize(ev.target.value)}
                            type="text"
                            className="form-control form-control-lg"
                            label="Pack Size"/>
                    </div>
                </div>
                <div className="row">

                    <div className="col form-outline mb-3">
                        <MDBInput
                            disabled={isDisabled}
                            value={country}
                            onChange={(ev: any) => setCountry(ev.target.value)}
                            type="text"
                            className="form-control form-control-lg"
                            label="Country Name"
                        />
                    </div>
                    <div className="d-flex flex-row col pt-2">
                        <p className="me-2">Priority:</p>
                        <MDBRadio
                            disabled={isDisabled}
                            onChange={(ev: any) => setPriority(ev.target.value)}
                            defaultChecked={priority === '0'}
                            name='inlineRadio'
                            value='0'
                            label='Normal'
                            inline/>
                        <MDBRadio
                            disabled={isDisabled}
                            onChange={(ev: any) => setPriority(ev.target.value)}
                            defaultChecked={priority === '1'}
                            name='inlineRadio'
                            value='1'
                            label='High'
                            inline/>
                    </div>
                    <div className="col form-outline mb-3">
                    </div>
                </div>

                <MDBRow>
                    <MDBCol size="5" className="mb-3" start>
                        <MDBInput
                            disabled={props.task !== undefined}
                            value={initiator}
                            onChange={(ev: any) => setInitiator(ev.target.value)}
                            type="email"
                            label="Initiator Email"
                            size="lg"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="5" className="mb-3" start>
                        <MDBInput
                            disabled={props.task !== undefined}
                            value={reviewor1}
                            onChange={(ev: any) => setReviewor1(ev.target.value)}
                            type="text"
                            label="Reviewer One Email"
                            size="lg"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="5" className="mb-3" start>
                        <MDBInput
                            disabled={props.task !== undefined}
                            value={reviewor2}
                            onChange={(ev: any) => setReviewor2(ev.target.value)}
                            type="text"
                            label="Reviewer Two Email"
                            size="lg"
                        />
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol size="5" className="mb-3" start>
                        <MDBInput
                            disabled={props.task !== undefined}
                            value={approver}
                            onChange={(ev: any) => setApprover(ev.target.value)}
                            type="email"
                            label="Approver Email"
                            size="lg"
                        />
                    </MDBCol>
                </MDBRow>

                <div className="text-center text-lg-start mt-4 pt-10">
                    {isLoading ?
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        :
                        props.task !== undefined ? (isDisabled ?
                            <>
                                {props.task ? getApproverEmail(props.task)?.toLowerCase() === userUtility.getUserData()?.email.toLowerCase() && (
                                    <button type="button" className="btn btn-primary"
                                            onClick={onEditClick}>Edit
                                    </button>) : <></>

                                }
                                {
                                    props.task && getPendingWith(props.task)?.toLowerCase() === userUtility.getUserData()?.email.toLowerCase() &&
                                    <button type="button" className="btn btn-primary ms-2"
                                            onClick={() => {
                                                props.task && modelRef.current?.open('approve')
                                            }}>Approve
                                    </button>
                                }

                            </> : <>
                                <button type="button" className="btn btn-primary"
                                        onClick={onEditClick}>Cancel
                                </button>
                                <button type="button" className="btn btn-primary ms-2"
                                        onClick={onButtonClick}>Save
                                </button>
                            </>) : (<>
                            <button type="button" className="btn btn-primary"
                                    onClick={onButtonClick}>Submit
                            </button>
                        </>)
                    }
                    {error.length > 0 && <label className="errorLabel ms-2">{error}</label>}
                </div>
            </form>
        </div>
    )
}

type UploadFileCommentModalProps = CreateTaskProps & {}


type UploadFileCommentModalRef = {
    open: (type: 'approve' | 'reject') => void,
}


const UploadFileCommentModal = forwardRef<UploadFileCommentModalRef, UploadFileCommentModalProps>((props, ref) => {
    const [showModal, setShowModal] = React.useState(false)
    const [error, setError] = React.useState('')
    const comment = useRef<string>('')
    const file = useRef<File | undefined>(undefined)
    const [type, setType] = React.useState<'approve' | 'reject'>()
    const navigate = useNavigate()

    useImperativeHandle(ref, () => {
        return {
            open: (type) => {
                setShowModal(true)
                setType(type)
            }
        }
    });


    const onSubmitClick = (task: Task) => {
        console.log(file.current)
        if (props.task?.actions[props.task?.status].key === 'initiator') {
            if (!file.current) {
                setError('please select a file because compulsory for initiator')
                return
            }
        } else {
            if (comment.current.length === 0) {
                setError('please add a history')
                return;
            }
        }
        const formData = new FormData();
        if (file.current) {
            formData.append('file', file.current);
        }
        formData.append('task_id', `${task.id}`);
        formData.append('status', `${type === 'approve' ? task.status + 1 : -1}`);
        if (comment.current.length) {
            formData.append('comment', comment.current);

        }

        postData({
            url: '/api/task/change-status',
            headers: {
                'content-type': 'multipart/form-data',
            },
            data: formData,
            onSuccess: (task: ResponseOuter<Task>) => {
                props.updateTask?.(task.data)
                setError(task.message)
                setShowModal(false)
            },
            onError: (e: AxiosError) => {
                switch (e.code) {
                    case ERR_BAD_REQUEST:
                        setError(`${e?.response?.data}`)
                        break
                    default:
                        setError("service unavailable")
                }
            }
        }, navigate)
    }

    return (
        <Modal
            show={showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body>
                <div className="d-flex flex-column">
                    <MDBRow className="pb-3">
                        <MDBCol md='4' offsetMd='4'>
                            <input type="file" id="formFile" onChange={(e) => {
                                file.current = e.target.files?.[0]
                            }}/>
                        </MDBCol>
                    </MDBRow>
                    <TextEditor onTextChange={(value) => {
                        comment.current = value
                    }}></TextEditor>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <label>{error}</label>
                <Button onClick={() => setShowModal(false)}>Cancel</Button>
                <Button onClick={() => {
                    props.task && onSubmitClick(props.task)
                }}>Submit</Button>
            </Modal.Footer>
        </Modal>)
})
