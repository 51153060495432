import React from "react";
import {Link, useNavigate} from "react-router-dom";
import {userUtility} from "../../models/user";

type NavbarProps = {
    userName: string,
    isAdmin: boolean,
}
export const NavBar = (props: NavbarProps) => {
    const navigate = useNavigate()
    const onSignOutClick = () => {
        userUtility.clearUserData()
        navigate('/login')
    }
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top ">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/home">DashBoard</Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        {
                            props.isAdmin && (
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to="/home/createTask">Create
                                            Task</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/home/createAccount">Create Account</Link>
                                    </li>
                                </>
                            )
                        }
                        <li className="nav-item">
                            <Link className="nav-link" to="/home?status=approved">Approved</Link>
                        </li>
                    </ul>
                    <form className="d-flex" role="search">
                        <div className="pt-2 pe-2">
                            {props.userName}
                        </div>
                        <button className="btn btn-outline-success" type="submit" onClick={onSignOutClick}>Sign out
                        </button>
                    </form>
                </div>
            </div>
        </nav>
    )
}
