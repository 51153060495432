import axios, {AxiosError, AxiosResponse} from "axios";
import {userUtility} from "../../models/user";
import {NavigateFunction} from "react-router-dom";
const baseUrl = 'https://api.doctivolifesciences.com'
export const ERR_BAD_REQUEST = "ERR_BAD_REQUEST"
export const ERR_NETWORK = "ERR_NETWORK"

export const postData = (request: {
    url: string,
    onSuccess?: (res: any) => void,
    onError?: (err: any) => void,
    data?: Record<string, any>,
    headers?: Record<string, string>
}, navigate?: NavigateFunction) => {
    console.log(JSON.stringify(request.data));
    const user = userUtility.getUserData()
    axios.post(baseUrl + request.url,
        request.data ?? {},
        {
            headers: { authorization: user?.authorization_token, ...(request.headers ?? {}) }
        }).then((response: AxiosResponse) => {
        console.log(response)
        request?.onSuccess?.(response.data)
    }).catch((error: AxiosError) => {
        console.log(error)
        if (error.status === 403) {
            userUtility.clearUserData()
            navigate?.('/login')
        } else {
            request?.onError?.(error)
        }
    })
}

export const getData = (request: {
    url: string,
    onSuccess?: (res: any) => void,
    onError?: (err: any) => void,
    data?: Record<string, string>,
    headers?: Record<string, string>
}, navigate?: NavigateFunction) => {
    const user = userUtility.getUserData()
    axios.get(baseUrl + request.url,
        {
            params: request.data ?? {},
            headers: { authorization: user?.authorization_token, ...(request.headers ?? {}) }
        }).then((response: AxiosResponse) => {
        console.log(response)
        request?.onSuccess?.(response.data)
    }).catch((error: AxiosError) => {
        console.log(error)
        if (error.status === 403) {
            userUtility.clearUserData()
            navigate?.('/login')
        } else {
            request?.onError?.(error)
        }
    })
}