import React, {useEffect, useState} from "react";
import {MDBBtn, MDBTable, MDBTableBody, MDBTableHead} from "mdb-react-ui-kit";
import {getFormatedDate} from "../../utils/uitls";
import {ERR_BAD_REQUEST, getData} from "../network/network";
import {AxiosError} from "axios";
import {ResponseOuter, TaskHistory} from "../../models/task";

type HistoryProps = {
    taskId: string
}

export const History = (props: HistoryProps) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('')
    const [data, setData] = useState<Array<TaskHistory>>([])

    useEffect(() => {
        getData({
            url: '/api/task/get-task-history',
            data: {
                task_id: props.taskId,
            },
            onSuccess: (data: ResponseOuter<Array<TaskHistory>>) => {
                setData(data.data)
                setLoading(false)
                setError('')
            },
            onError: (err: AxiosError) => {
                switch (err.code) {
                    case ERR_BAD_REQUEST:
                        setError("something went wrong")
                        break
                    default:
                        setError("service unavailable")
                }
                setLoading(false)
            }
        })
    }, [props.taskId]);

    if (loading) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }

    if (error.length > 0) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    {error}
                </div>
            </div>
        )
    }

    if (data.length === 0) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    No data available
                </div>
            </div>
        )
    }

    return (
        <div className="container">
            <MDBTable align='middle'>
                <MDBTableHead>
                    <tr>
                        <th scope='col'>Email</th>
                        <th scope='col'>Action</th>
                        <th scope='col'>Date</th>
                        <th scope='col'>File</th>
                        <th scope='col'>Comment</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                    {data.map((item: TaskHistory) => {
                        return <tr>
                            <td>
                                <p className='fw-normal'>
                                    {item.email}
                                </p>
                            </td>
                            <td>
                                <p className='fw-normal'>
                                    <b>{ item.status !== -1 ? 'Approved' : 'Reject'}</b>
                                    {' as a ' + item.user_role}
                                </p>
                            </td>
                            <td>
                                {getFormatedDate(item.created_at)}
                            </td>
                            <td>
                                {
                                    item.file ?
                                        <>
                                            <MDBBtn
                                                color='link'
                                                rounded
                                                size='sm'
                                                href={'https://api.doctivolifesciences.com/api/view-file?file_path=' + item.file}
                                                target="_blank">
                                                View
                                            </MDBBtn>
                                            <MDBBtn
                                                color='link'
                                                rounded
                                                size='sm'
                                                href={'https://api.doctivolifesciences.com/uploads/' + item.file}
                                                target="_blank">
                                                Download
                                            </MDBBtn>
                                        </> : <></>
                                }
                            </td>
                            <td>
                                <div dangerouslySetInnerHTML={{__html: item.comment}}/>
                            </td>
                        </tr>
                    })}

                    </MDBTableBody>
                    </MDBTable>

                    </div>
                    )
                    }