import React, {useEffect, useState} from "react";
import {Outlet, useNavigate} from "react-router-dom";
import {NavBar} from "../navbar/NavBar";
import {User, userUtility} from "../../models/user";

export const Home = () => {
    const [user, setUser] = useState<User | null>(null);
    const navigate = useNavigate()
    useEffect(() => {
        const user = userUtility.getUserData()
        if (user != null) {
            setUser(user)
        } else {
            navigate('/login')
        }
    }, [navigate])

    return (
        <div className="d-flex flex-column min-vh-100">
            {user && <>
                <header>
                    <NavBar userName={user.username} isAdmin={user.user_type === 1}/>
                </header>
                <main className="mb-auto mt-5 pt-2">
                    <Outlet/>
                </main>
            </>
            }
        </div>)
}
