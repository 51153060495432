
export type User = {
    email : string
    created_at : string
    id: string
    mobile: string
    status: string
    user_type: number
    username: string
    authorization_token: string
}

export const userUtility = (function () {
    let userData: User | null = null
    return {
        getUserData: (): User | null => {
            if (!userData) {
                const userString :string | null= localStorage.getItem('user')
                if (!userString || userString?.length === 0) {
                    return null
                }
                userData = JSON.parse(atob(userString))
            }
            return userData
        },
        setUserData: (data: User) => {
            localStorage.setItem('user', btoa(JSON.stringify(data)))
            userData = data
        },
        clearUserData: () => {
            localStorage.clear()
            userData = null
        }
    }
})()