import {useNavigate, useParams} from "react-router-dom";
import {CreateTask} from "../createTask/CreateTask";
import {useCallback, useEffect, useMemo, useState} from "react";
import {getData} from "../network/network";
import {Task, TaskOuter} from "../../models/task";
import {MDBBtn, MDBBtnGroup} from "mdb-react-ui-kit";
import {History} from "../history/History";

enum Section {
    Details = "Detail",
    History = "History",
}


export const TaskDetail = () => {
    const {id} = useParams()
    const [loading, setLoading] = useState(true)
    const [task, setTask] = useState<Task | null>(null)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const [section, setSection] = useState<Section>(Section.Details);

    useEffect(() => {
        if (!id) {
            setLoading(false)
            setError('id cannot be undefined')
            return
        }
        setLoading(true)
        getData({
            url: '/api/task/get-task-details',
            data: {
                task_id: id
            },
            onSuccess: (task: TaskOuter) => {
                setTask(task.task)
                setLoading(false)
                setError('')
            },
            onError: () => {
                setError('service unavailable')
                setLoading(false)
            }
        }, navigate)
    }, [id, navigate])

    const updateTask = useCallback((task: Task) => {
        setTask(task)
    },[setTask])

    const pageComponent = useMemo(() => {
        switch (section) {
            case Section.Details:
                return <CreateTask task={task} updateTask={updateTask} />
            default:
                return (id && <History taskId={id} />)

        }
    }, [section, task, updateTask, id])

    if (loading) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }

    if (error.length > 0) {
        return (<div className="container mt-4">
                <div className="row justify-content-center align-items-center">
                    {error}
                </div>
            </div>
        )
    }

    return (<div className="conatiner">
        <div className="row align-items-center justify-content-center">
            <div className="col-3 mb-3 mt-3">
                <MDBBtnGroup shadow='0' aria-label='Basic example'>
                    <MDBBtn color={section === Section.Details ? 'primary': 'secondary'} onClick={() => {
                        setSection(Section.Details)
                    }}>
                        Detail
                    </MDBBtn>
                    <MDBBtn color={section === Section.History ? 'primary': 'secondary'} onClick={() => {
                        setSection(Section.History)
                    }}>
                        History
                    </MDBBtn>
                </MDBBtnGroup>
            </div>
        </div>
        {pageComponent}
    </div>)
}