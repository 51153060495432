import {useState} from "react";
import {isEmail, isPhone} from "../../utils/uitls";
import {ERR_BAD_REQUEST, postData} from "../network/network";
import {AxiosError} from "axios";
import { MDBInput, MDBRadio} from "mdb-react-ui-kit";

export const CreateAccount = () => {
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [username, setUserName] = useState('')
    const [phone, setPhone] = useState('')
    const [error, setError] = useState('')
    const [userType, setUserType] = useState('Normal')

    const onButtonClick = () => {
        if (username.length === 0) {
            setError('username incorrect')
            return
        }
        if (email.length === 0 || !isEmail(email)) {
            setError('email incorrect')
            return
        }
        if (password.length === 0) {
            setError('password incorrect')
            return
        }
        if (companyName.length === 0) {
            setError('companyName incorrect')
            return
        }
        if (phone.length === 0 || !isPhone(phone)) {
            setError('phone incorrect')
            return
        }
        postData({
          url: "/api/users/register-user",
          data: {
              "username": username,
              "email": email,
              "password": password,
              "mobile": phone,
              "user_type" : userType === 'Normal' ? '0' : '1',
              "company": companyName,
          },
          onSuccess: () => {
              setLoading(false)
              setError("Account Successfully Created")
          },
          onError: (e: AxiosError) => {
              switch (e.code) {
                  case ERR_BAD_REQUEST:
                      setError("something went wrong")
                      break
                  default:
                      setError("service unavailable")
              }
              setLoading(false)
          }
        })
        setLoading(true)
    }

    return (
        <div className="container">
            <div className="row justify-content-center align-items-center m-4">
                Create Account
            </div>
            <div className="row justify-content-center align-items-center">
                <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
                    <form>
                        <div className="form-outline mb-3">
                            <MDBInput
                                type="text"
                                value={username}
                                label="Enter your username here"
                                onChange={(ev: any) => {
                                    setUserName(ev.target.value);
                                }}/>
                        </div>

                        <div className="form-outline mb-3">
                            <MDBInput
                                type="email"
                                className="form-control form-control-lg"
                                value={email}
                                label="Enter your email here"
                                onChange={(ev: any) => setEmail(ev.target.value)}/>
                        </div>

                        <div className="form-outline mb-3">
                            <MDBInput
                                type="password"
                                className="form-control form-control-lg"
                                value={password}
                                label="Enter your password here"
                                onChange={(ev: any) => setPassword(ev.target.value)}/>
                        </div>

                        <div className="form-outline mb-3">
                            <MDBInput
                                type="text"
                                className="form-control form-control-lg"
                                value={companyName}
                                label="Enter your Compnay"
                                onChange={(ev: any) => setCompanyName(ev.target.value)}/>
                        </div>

                        <div className="form-outline mb-3">
                            <MDBInput
                                type="text"
                                className="form-control form-control-lg"
                                value={phone}
                                label="Phone Number eg(999999999)"
                                onChange={(ev: any) => setPhone(ev.target.value)}/>
                        </div>
                        <div>
                            <MDBRadio
                                name='flexRadioDefault'
                                id='flexRadioDefault1'
                                label='Admin'
                                defaultChecked={userType === 'Admin'}
                                onClick={() => {
                                    setUserType('Admin')
                                }}
                            />
                            <MDBRadio
                                name='flexRadioDefault'
                                id='flexRadioDefault2'
                                label='Normal'
                                defaultChecked={userType === 'Normal'}
                                onClick={() => {
                                    setUserType('Normal')
                                }}
                            />
                        </div>
                        <div className="text-center text-lg-start mt-4 pt-10">
                            {isLoading ?
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                : <button type="button" className="btn btn-primary"
                                          onClick={onButtonClick}>Create Account</button>}
                            {error.length > 0 && <label className="errorLabel ms-2">{error}</label>}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
